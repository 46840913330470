import React from "react"
import ChainPicker from "../components/chainpicker"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {formatCurrency, formatPct, stableSort} from "../utils"
import {optionName} from "../data/options"
import TableSortLabel from '@mui/material/TableSortLabel';
import CircularProgress from '@mui/material/CircularProgress';
import {logEvent} from "../analytics";
/*
    Option Picker allows for selecting a specific option {ticker, expiry, strike, optiontype}
        The user can select a ticker and expiry from the ChainPicker control
        OptionPicker will render the available strikes for that ticker/expiry/type
*/
export default class OptionPicker extends React.Component {

  state = {
    selected_ticker: "AMZN",strikes:[],searching:false,displayed_ticker:"",displayed_expiry:"",displayed_option_type:"",
    sort_column:'iv',sort_order:'asc',selected_strike:null
  }

  //#region User Input
  //the user has selected a new option chain from the ChainPicker {ticker, expiry, option_type}
  chainChanged = (new_chain) => {
    this.setState({
        selected_ticker:new_chain.ticker,
        selected_expiry:new_chain.expiry,
        selected_option_type:new_chain.option_type
      });
  }

  handleSortRequest(requested_col) {
    //if we're already sorting by this column, flip the order
    let new_sort_order = this.state.sort_order;
    if (this.state.sort_column===requested_col) { 
        new_sort_order = (this.state.sort_order==='asc')?'desc':'asc';
    } 
    let params = {sort_column:requested_col, sort_order:new_sort_order}
    this.setState(params)
    logEvent("requestOptionPickerSort", params)
  }

  // the user has selected a strike row in the table
  selectStrike(strike) {
    this.setState({selected_strike:strike},()=> this.notifyOfStrikeSelected())
  }

  //notify container that a new strike has been selected
  notifyOfStrikeSelected() {  
    if (!this.props.onStrikeSelected) return;

    const packet = {
        strike:this.state.selected_strike,
        expiry:this.state.selected_expiry,
        min_strike:this.state.min_strike,
        max_strike:this.state.max_strike,
        step:this.state.step
    }
    this.props.onStrikeSelected(packet); //pass it up to container
    logEvent("strikeSelected",packet)
  }
  //#endregion

  fetchChainStrikes() {
     //grab the values of these at the moment the request was made (they could change while we're fetching - we want to display this info once we get the data back)
     const requested_ticker=this.state.selected_ticker; 
     const requested_expiry=this.state.selected_expiry; 
     const requested_option_type=this.state.selected_option_type;

    const url = `${process.env.GATSBY_TICKER_API_ENDPOINT}/${requested_ticker}/chain?expiry=${requested_expiry}&option_type=${requested_option_type}`
    const params = {url:url, expiry:requested_expiry, requested_option_type: requested_option_type};
    
    this.setState({strikes:[],searching:true},()=>
        fetch(url)
            .then(res => res.json())
            .then((data)=>this.setState({
                strikes: data['strikes'],
                min_strike: data['min_strike'],
                max_strike: data['max_strike'],
                step: data['step'],
                displayed_ticker:requested_ticker,
                displayed_expiry:requested_expiry,
                displayed_option_type:requested_option_type,
                searching:false
                }))
            .then(()=>logEvent("requestOptionChain_Success", params))
            .catch((e)=>logEvent("requestOptionChain_Failed", params))
    );
  }

  //#region Form methods
  canEnableSearch() {
    return (this.formValid() && !this.state.searching)
  }
  formValid() { 
    return (this.state.selected_ticker!=="" && this.state.selected_expiry!=="")
  }
  formSubmitted = (event) => {
    this.fetchChainStrikes();
  }
  //#endregion

  //#region UI methods
  strikeTable() {
    if (this.state.searching===true) {
        return <CircularProgress color="inherit" />;
    }
    if (this.state.strikes.length<=0) {
        return <></>;
    }
    return (<Table stickyHeader size="small" aria-label="strike table">
    <TableHead>
        <TableRow key="head">
          <TableCell  key="strike">
          <TableSortLabel
              active={this.state.sort_column==="strike"} // do some validation to decide where the icon will be displayed
              direction={this.state.sort_order} // hardcoded for now
              onClick={(e) => {this.handleSortRequest('strike')}}>
              Strike
            </TableSortLabel>
            </TableCell>
          <TableCell key="mark">
            <TableSortLabel
              active={this.state.sort_column==="mark"} // do some validation to decide where the icon will be displayed
              direction={this.state.sort_order} // hardcoded for now
              onClick={(e) => {this.handleSortRequest('mark')}}>
            Mark
            </TableSortLabel>
          </TableCell>
          <TableCell key="impliedVolatility">
          <TableSortLabel
              active={this.state.sort_column==="impliedVolatility"} // do some validation to decide where the icon will be displayed
              direction={this.state.sort_order} // hardcoded for now
              onClick={(e) => {this.handleSortRequest('impliedVolatility')}}>
            IV
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {stableSort(this.state.strikes,this.state.sort_order, this.state.sort_column ).map((strike) => {
          if (strike.mark > 0.0 && strike.impliedVolatility > 0.0099) {
            let is_selected = (this.state.selected_strike && (this.state.selected_strike.strike === strike.strike))
            return (<TableRow key={strike.strike} selected={is_selected} onClick={() => this.selectStrike(strike)}>
                    <TableCell>{strike.strike}</TableCell>
                    <TableCell>{formatCurrency(strike.mark)}</TableCell>
                    <TableCell>{formatPct(strike.impliedVolatility*100,2)}</TableCell>
                  </TableRow>);
          }
          return <></>
        }
      )}
      </TableBody>
    </Table>);
  }
  chainDetail() {
    if (this.state.strikes.length<=0) {
        return <></>;
    }
    return <h6 className="card-subtitle">{optionName(this.state.displayed_option_type)} Strikes for {this.state.displayed_ticker} on {this.state.displayed_expiry}</h6>
  }

  render() {
    //{\"strike\":4790.0,\"lastPrice\":0.15,\"lastTradeDate\":1612449000000,\"mark\":0.02,\"impliedVolatility\":1.4375028125},
    
    return (
      <div>
        <div className="row" >
          <div className="card" style={{width:"100%"}}>
            <div className="card-body">
              <h4 className="card-title" >Load from option chain</h4>
                <ChainPicker onChainChanged={this.chainChanged} defaultTicker={this.state.selected_ticker}/>
          
                <button id="go" disabled={!this.canEnableSearch()} onClick={this.formSubmitted}  type="submit"  className="btn btn-rounded btn-default btn-outline">
                  <i className="fas fa-play-circle  m-r-5"></i>Show
                </button>
              </div>
            </div>
        </div>

        <div className="row" >
          <div className="card" style={{width:"100%",visibility:(this.state.searching===true || this.state.strikes.length>0)?'visible':'hidden'}}>
            <div className="card-body">
              {this.chainDetail()}
              {this.strikeTable()}
            </div>
          </div>
        </div>
    </div>
    )
  }
  //#endregion
}